import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SmallBanner from "../components/smallBanner";

const PrivacyPolicy = () => {
  const lang = "pl";

  return (
    <Layout
      seoTitle="Polityka prywatności"
      lang={lang}
      translationEN="/en/privacy-policy/"
      translationDE="/de/datenschutzerklarung/"
    >
      <SmallBanner title="Polityka Prywatności" lang={lang} />

      <section className="default-main">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <p className="bold">1. Administrator danych</p>
              <p>
                Palettenwerk Kozik Sp. z o.o., ul. Przemysłowa 219, 34-240
                Jordanów, KRS: 0000966492 (dalej: PalettenWerk).
              </p>
              <p className="bold">2. Dane kontaktowe</p>
              <p>
                Z PalettenWerk można się skontaktować poprzez adres email:
                palety@palettenwerk.pl, pod numerem telefonu +48 883 323 708 lub
                pisemnie: Palettenwerk Kozik Sp. z o.o., ul. Przemysłowa 219,
                34-240 Jordanów. PalettenWerk nie wyznaczył inspektora ochrony
                danych.
              </p>
              <p className="bold">
                3. Cele przetwarzania oraz podstawa prawna przetwarzania
              </p>
              <ul>
                <li>
                  Pani/Pana dane będą przetwarzane w celu udzielenia dodatkowych
                  informacji handlowych z zakresu oferty PalettenWerk. Podanie
                  danych jest dobrowolne i umożliwia uzyskanie informacji
                  handlowej – podstawą prawną przetwarzania danych jest
                  Pani/Pana zgoda pod warunkiem, że ta zgoda została przez
                  Panią/Pana udzielona, w razie nieudzielenia zgody dane osobowe
                  nie są przetwarzane w tym celu.
                </li>
                <li>
                  Pani/Pana dane będą przetwarzane w celu marketingu
                  bezpośredniego dotyczącego produktów i usług własnych
                  PalettenWerk – podstawą prawną przetwarzania danych jest
                  Pani/Pana zgoda pod warunkiem, że ta zgoda została przez
                  Panią/Pana udzielona, w razie nieudzielenia zgody dane osobowe
                  nie są przetwarzane w tym celu.
                </li>
                <li>
                  Pani/Pana dane będą przetwarzane w celu realizacji usług
                  takich jak np. udział w webinarze – podstawą prawną
                  przetwarzania danych jest Pani/Pana zgoda pod warunkiem, że ta
                  zgoda została przez Panią/Pana udzielona, w razie
                  nieudzielenia zgody dane osobowe nie są przetwarzane w tym
                  celu.
                </li>
                <li>
                  Pani/Pana dane będą przetwarzane w celu przeprowadzenia
                  procesu rekrutacji na dane stanowisko – podstawą prawną
                  przetwarzania danych jest Pani/Pana zgoda udzielona przy
                  wypełnianiu formularza zgłoszeniowego (aplikacja na dane
                  stanowisko).
                </li>
              </ul>
              <p className="bold">
                4. Okres, przez który dane będą przechowywane
              </p>
              <p>
                Dane będą przetwarzane przez 2 lata lub do momentu cofnięcia
                przez Panią/Pana zgody na przetwarzanie danych osobowych w
                celach marketingowych.
              </p>
              <p className="bold">5. Odbiorcy danych</p>
              <p>
                Pani/Pana dane mogą być przekazywane podmiotom przetwarzającym
                dane osobowe na zlecenie PalettenWerk w szczególności dostawcom
                usług IT i biurom rachunkowym – przy czym takie podmioty będą
                przetwarzać dane na podstawie umowy z PalettenWerk i wyłącznie
                zgodnie z poleceniami PalettenWerk.
              </p>
              <p className="bold">
                6. Przekazywanie danych poza Europejski Obszar Gospodarczy
              </p>
              <p>
                Pani/Pana dane nie będą przekazywane do odbiorców znajdujących
                się w państwach poza Europejskim Obszarem Gospodarczym.
              </p>
              <p className="bold">7. Prawa osoby, której dane dotyczą</p>
              <p>Przysługuje Pani/Panu prawo:</p>
              <ul>
                <li>dostępu do Pani/Pana danych</li>
                <li>żądania ich sprostowania</li>
                <li>żądania ich usunięcia</li>
                <li>żądania ograniczenia przetwarzania,</li>
                <li>wycofania zgody,</li>
                <li>
                  prawo do przenoszenia danych osobowych, tj. do otrzymania od
                  PalettenWerk Pani/Pana danych osobowych w pliku o powszechnie
                  używanym formacie,
                </li>
                <li>
                  wniesienia skargi do organu nadzorczego zajmującego się
                  ochroną danych osobowych.
                </li>
              </ul>
              <p>
                W celu realizacji powyżej wymienionych praw należy skontaktować
                się z PalettenWerk. Można to zrobić np. przesyłając wniosek na
                adres siedziby PalettenWerk lub e-mailowo na adres
                palety@palettenwerk.pl
              </p>
              <p className="bold">8. Dobrowolność podania danych</p>
              <p>
                Podanie danych osobowych w celach marketingowych jest
                dobrowolne. Podanie danych osobowych w celu rozpatrywania
                ewentualnej reklamacji jest dobrowolne, jednak ich niepodanie
                uniemożliwi przyjęcie i rozpatrzenie reklamacji.
              </p>
              <p className="bold">9. Podjęte środki ochrony danych</p>
              <ul>
                <li>
                  Ochrona danych odbywa się zgodnie z wymogami powszechnie
                  obowiązujących przepisów prawa, a ich przechowywanie ma
                  miejsce na zabezpieczonych serwerach.
                </li>
                <li>
                  Szanujemy prawo do prywatności i dbamy o bezpieczeństwo
                  danych. W tym celu używany jest m.in. bezpieczny protokół
                  szyfrowania komunikacji (SSL).
                </li>
                <li>
                  Wdrożyliśmy szyfrowanie danych oraz mamy wprowadzoną kontrolę
                  dostępu dzięki czemu minimalizujemy skutki ewentualnego
                  naruszenia bezpieczeństwa danych.
                </li>
              </ul>
              <p className="bold">10. Pliki cookies</p>
              <ul>
                <li>
                  Witryna palettenwerk.pl używa cookies. Są to niewielkie pliki
                  tekstowe wysyłane przez serwer www i przechowywane przez
                  oprogramowanie komputera przeglądarki. Kiedy przeglądarka
                  ponownie połączy się ze stroną, witryna rozpoznaje rodzaj
                  urządzenia, z którego łączy się użytkownik. Parametry
                  pozwalają na odczytanie informacji w nich zawartych jedynie
                  serwerowi, który je utworzył. Cookies ułatwiają więc
                  korzystanie z wcześniej odwiedzonych witryn. Gromadzone
                  informacje dotyczą adresu IP, typu wykorzystywanej
                  przeglądarki, języka, rodzaju systemu operacyjnego, dostawcy
                  usług internetowych, informacji o czasie i dacie, lokalizacji
                  oraz informacji przesyłanych do witryny za pośrednictwem
                  formularza kontaktowego.
                </li>
                <li>
                  Zebrane dane służą do monitorowania i sprawdzenia, w jaki
                  sposób użytkownicy korzystają z naszych witryn, aby usprawniać
                  funkcjonowanie serwisu zapewniając bardziej efektywną i
                  bezproblemową nawigację. Monitorowania informacji o
                  użytkownikach dokonujemy korzystając z narzędzia Google
                  Analitics, które rejestruje zachowanie użytkownika na stronie.
                  Cookies identyfikuje użytkownika, co pozwala na dopasowanie
                  treści witryny, z której korzysta, do jego potrzeb.
                  Zapamiętując jego preferencje, umożliwia odpowiednie
                  dopasowanie skierowanych do niego reklam. Stosujemy pliki
                  cookies, aby zagwarantować najwyższy standard wygody naszego
                  serwisu, a zebrane dane są wykorzystywane jedynie wewnątrz
                  firmy PalettenWerk w celu optymalizacji działań.
                </li>
                <li>
                  Na naszej witrynie wykorzystujemy następujące pliki cookies:
                  <br />
                  <ul>
                    <li>
                      „niezbędne” pliki cookies, umożliwiające korzystanie z
                      usług dostępnych w ramach serwisu, np. uwierzytelniające
                      pliki cookies wykorzystywane do usług wymagających
                      uwierzytelniania w ramach serwisu;
                    </li>
                    <li>
                      pliki cookies służące do zapewnienia bezpieczeństwa, np.
                      wykorzystywane do wykrywania nadużyć w zakresie
                      uwierzytelniania w ramach serwisu;
                    </li>
                    <li>
                      „wydajnościowe” pliki cookies, umożliwiające zbieranie
                      informacji o sposobie korzystania ze stron internetowych
                      serwisu;
                    </li>
                    <li>
                      „reklamowe” pliki cookies, umożliwiające dostarczanie
                      użytkownikom treści reklamowych bardziej dostosowanych do
                      ich zainteresowań.
                    </li>
                  </ul>
                </li>
                <li>
                  Użytkownik w każdej chwili ma możliwość wyłączenia lub
                  przywrócenia opcji gromadzenia cookies poprzez zmianę ustawień
                  w przeglądarce internetowej. Instrukcja zarządzania plikami
                  cookies jest dostępna na stronie:
                  http://www.allaboutcookies.org/manage-cookies
                </li>
                <li>
                  Dodatkowe dane osobowe, jak adres e-mail, zbierane są jedynie
                  w miejscach, w których użytkownik wypełniając formularz
                  wyraźnie wyraził na to zgodę. Powyższe dane zachowujemy i
                  wykorzystujemy tylko do potrzeb niezbędnych do wykonania danej
                  funkcji.
                </li>
              </ul>
              <p>
                Więcej o ciasteczkach (cookies) w naszej{" "}
                <Link className="color-btn--main" to="/polityka-cookies/">
                  Polityce Plików Cookies.
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
